import {useState} from 'react';
import styles from "./Policy.module.scss";
import { useEffect } from 'react';
import { useTranslation  } from 'react-i18next';
import axios from "axios";
import {Helmet} from "react-helmet";

export default function Policy() {
  const { i18n } = useTranslation();
  const [title, setTitle] = useState('');
  const [descr, setDescr] = useState('');
  const [seoText, setSeoText] = useState('');

  const getApiCats = async(id) => {
    console.log(id);
    await axios('https://api.sicvolo.org/wp-json/wp/v2/pages?per_page=100')
    .then(res =>{
      console.log(res);
      const data = res.data;
      console.log(data);
      const apiCat = data?.filter((obj) => obj.id === 1944);
      console.log(apiCat);

      if(i18n.language === "en"){
        setTitle(apiCat[0].acf.title_en);
        setDescr(apiCat[0].acf.description_en);
        setSeoText(apiCat[0].acf.seo_text_en);
      }
      if(i18n.language === "sp"){
        setTitle(apiCat[0].acf.title_sp);
        setDescr(apiCat[0].acf.description_sp);
        setSeoText(apiCat[0].acf.seo_text_sp);
      }
      if(i18n.language === "fr"){
        setTitle(apiCat[0].acf.title_fr);
        setDescr(apiCat[0].acf.description_fr);
        setSeoText(apiCat[0].acf.seo_text_fr);
      }
      if(i18n.language === "it"){
        setTitle(apiCat[0].acf.title_it);
        setDescr(apiCat[0].acf.description_it);
        setSeoText(apiCat[0].acf.seo_text_it);
      }
      if(i18n.language === "ua"){
        setTitle(apiCat[0].acf.title_ua);
        setDescr(apiCat[0].acf.description_ua);
        setSeoText(apiCat[0].acf.seo_text_ua);
      }
      if(i18n.language === "ru"){
        setTitle(apiCat[0].acf.title_ru);
        setDescr(apiCat[0].acf.description_ru);
        setSeoText(apiCat[0].acf.seo_text_ru);
      }
       
    } )
   
  }
  useEffect(()=>{ 
    getApiCats();
    window.scrollTo(0, 0);
    if(window.fbq){
      window.fbq('track', 'ViewContent', { 
          content_type: 'Privacy Policy page',
          content_ids: ['1234'],
          content_name: 'Privacy Policy page',
          content_category: 'Privacy Policy page',
         
        })
      window.fbq('track', 'PageView')
    }
  }, [])
  
  return (
    <>
    <Helmet>
        <link rel="canonical" href={`https://sicvolo.com${window.location.pathname}`} />
            <title>{title}</title> 
            <meta name="description" content={descr} />
            <meta property="og:title" content={title} />
            <meta property="og:image" content="https://sicvolo.com/img/sl2.png" />
            <meta property="og:description" content={descr} />
        </Helmet>


        <div className={styles.policy_wrapper}>

      {i18n.language === "en" && (<>
        <h1 >Privacy Policy</h1>
     
     <p className={styles.policy_wrapper}>At Sic Volo, accessible from sicvolo.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Sic Volo and how we use it.</p>
     <p className={styles.policy_wrapperLast}>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>





<div className={styles.policy_item}>
       <p className={styles.policy_item_title}>General Data Protection Regulation (GDPR)</p>
       <div className={styles.policy_item_text}>
           <p>We are a Data Controller of your information.</p>
           <p>Sic Volo legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</p>
           <ul>
             <li>Sic Volo needs to perform a contract with you</li>
             <li>You have given Sic Volo permission to do so</li>
             <li>Processing your personal information is in Sic Volo legitimate interests</li>
             <li>Sic Volo needs to comply with the law</li>
           </ul>
           <p>Sic Volo will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>
           <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.</p>
           <p>In certain circumstances, you have the following data protection rights:</p>
           <ul>
             <li>The right to access, update or to delete the information we have on you.</li>
             <li>The right of rectification.</li>
             <li>The right to object.</li>
             <li>The right of restriction.</li>
             <li>The right to data portability</li>
             <li>The right to withdraw consent</li>
           </ul>

       </div>
     </div>


     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>Log Files</p>
       <div className={styles.policy_item_text}>
           <p>Sic Volo  follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
         
       </div>
     </div>


     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>Cookies and Web Beacons</p>
       <div className={styles.policy_item_text}>
           <p>Like any other website, Sic Volo  uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
         
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>Privacy Policies</p>
       <div className={styles.policy_item_text}>
           <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Sic Volo.</p>  
           <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Sic Volo, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>  
           <p>Note that Sic Volo  has no access to or control over these cookies that are used by third-party advertisers.</p>
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>Third Party Privacy Policies</p>
       <div className={styles.policy_item_text}>
           <p>Sicwolo's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p> 
           <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>   
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>Children's Information</p>
       <div className={styles.policy_item_text}>
           <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>  
           <p>Sic Volo  does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p> 
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>Online Privacy Policy Only</p>
       <div className={styles.policy_item_text}>
           <p>Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in sicwolo. This policy is not applicable to any information collected offline or via channels other than this website.</p>   
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>Consent</p>
       <div className={styles.policy_item_text}>
           <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>   
       </div>
     </div>
      </>)}
   

      {i18n.language === "ua" && (<>
        <h1 >Політика конфіденційності</h1>
     
   
     <p className={styles.policy_wrapperLast}>Ця Політика конфіденційності персональних даних (далі — Політика конфіденційності) діє стосовно всієї інформації, яку Інтернет-магазин SICVOLO.COM  може отримати про Користувача під час використання сайту, його програм і продуктів.</p>





    <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>1.	ВИЗНАЧЕННЯ ТЕРМІНІВ</p>
       <div className={styles.policy_item_text}>
           <p>1.1 У цій Політиці конфіденційності використовуються наступні терміни:</p>
           <p>1.1.1. «Адміністрація сайту Інтернет-магазину (далі — Адміністрація сайту)» — уповноважені співробітники на управління сайтом, що діють від імені Правомірного користувача Торгової марки і Порталу (далі — правомірний користувач), які організовують і (або) здійснюють обробку персональних даних, а також визначають цілі обробки персональних даних, склад персональних даних, які підлягають обробці, дії (операції), що здійснюються з персональними даними.</p>
         <p>1.1.2. «Персональні дані» — будь-яка інформація, що стосується прямо або опосередковано певної або обумовленої фізичної особи (суб'єктові персональних даних).</p>
         <p>1.1.3. «Обробка персональних даних» — будь-яка дія (операція) або сукупність дій (операцій), що здійснюються з використанням засобів автоматизації або без використання таких засобів з персональними даними, включаючи збір, запис, систематизацію, накопичення, зберігання, уточнення (оновлення, зміна), витяг, використання, передачу (поширення, надання, доступ), знеособлення, блокування, видалення, знищення персональних даних.</p>
         <p>1.1.4. «Конфіденційність персональних даних» — обов'язкова для дотримання Оператором або іншими особами, хто отримав доступ до персональних даних особи, вимога не допускати їх поширення без згоди суб'єкта персональних даних або без наявності іншої законної підстави.</p>
         <p>1.1.5. «Користувач сайту Інтернет-магазину (далі Користувач)» — особа, яка має доступ до Сайту за допомогою мережі Інтернет і використовує Сайт інтернет-магазину.</p>
         <p>1.1.6. «Cookies» — невеликий фрагмент даних, відправлений веб-сервером, що зберігається на комп'ютері користувача, який веб-клієнт або веб-браузер щоразу пересилає веб-серверу в HTTP-запиті при спробі відкрити сторінку відповідного сайту.</p>
           <p>1.1.7. «IP-адреса» — унікальна мережева адреса вузла в комп'ютерній мережі, побудованій за протоколом IP.</p>

       </div>
    </div>



    <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>2.	ЗАГАЛЬНІ ПОЛОЖЕННЯ</p>
       <div className={styles.policy_item_text}>
           <p>2.1. Використання Користувачем сайту Інтернет-магазину означає згоду з цією Політикою конфіденційності та умовами обробки персональних даних Користувача.</p>
           <p>2.2. Погоджуючись із використанням файлів-cookies при першому відвіданні нашого веб-сайту, відповідно до положень даних Правил, Користувач надає дозвіл на використання файлів-cookies при кожному наступному візиті.</p>
           <p>2.3. У разі незгоди з умовами Політики конфіденційності Користувач повинен припинити використання сайту Інтернет-магазину.</p>
           <p>2.4. Ця Політика конфіденційності застосовується лише до сайту Інтернет-магазину SICVOLO.COM. Інтернет-магазин не контролює і не несе відповідальність за сайти третіх осіб, на які Користувач може перейти за посиланнями, доступними на сайті інтернет-магазину.</p>
           <p>2.5. Адміністрація сайту не перевіряє правдивість персональних даних, що надаються Користувачем сайту Інтернет-магазину.</p>
           <p>2.6. Перед тим, як розкрити на сайті персональну інформацію третьої особи, Користувач повинен отримати згоду цієї особи як на розкриття, так і на обробку цієї інформації відповідно до даних правил.</p>
         

       </div>
    </div>


     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>3.	ПРЕДМЕТ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ</p>
       <div className={styles.policy_item_text}>
           <p>3.1. Справжня Політика конфіденційності встановлює зобов'язання Адміністрації сайту інтернет-магазину щодо нерозголошення та забезпечення режиму захисту конфіденційності персональних даних, які Користувач повинен на вимогу надати Адміністрації сайту при реєстрації на сайті інтернет-магазину або при оформленні замовлення для придбання Товару.</p>
           <p>3.2. Персональні дані, що відкриті для обробки в рамках цієї Політики конфіденційності, надаються Користувачем шляхом заповнення реєстраційної форми на Сайті інтернет-магазину SICVOLO.COM в розділі «Оформити замовлення» і містять наступну інформацію:</p>
           <p>3.2.1. прізвище, ім'я, по батькові Користувача;</p>
           <p>3.2.2. контактний телефон Користувача;</p>
           <p>3.2.3. адреса електронної пошти (e-mail);</p>
            <p>3.2.4. адреса доставки Товару;</p>
            <p>3.2.5. місце проживання Користувача.</p>
            <p>3.3. Інтернет-магазин захищає Дані, які автоматично передаються в процесі перегляду рекламних блоків і при відвідуванні сторінок, на яких встановлено статистичний скрипт системи:</p>
            <ul>
              <li>IP-адреса;</li>
              <li>інформація з cookies;</li>
              <li>інформація про браузер (чи іншу програму, яка здійснює доступ до показу реклами);</li>
              <li>час доступу;</li>
              <li>адреса сторінки, на якій розташовано рекламний блок;</li>
              <li>реферер (адреса попередньої сторінки).</li>
            </ul>
            <p>3.3.1. Відключення cookies може спричинити неможливість доступу до частин сайту Інтернет-магазину, що вимагають авторизації.</p>
            <p>3.3.2. Інтернет-магазин здійснює збір статистики про IP-адреси своїх відвідувачів. Дана інформація використовується з метою виявлення і вирішення технічних проблем, для контролю законності проведених фінансових платежів.</p>
            <p>3.4. Будь-яка інша персональна інформація не обговорена вище (історія покупок, використовувані браузери і операційні системи і т.д.) підлягає надійному зберіганню і нерозповсюдженню за винятком випадків, передбачених в п.п. 5.2. і 5.3. цієї Політики конфіденційності.</p>
       </div>
     </div>


     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>4.	МЕТА ЗБОРУ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ КОРИСТУВАЧА</p>
       <div className={styles.policy_item_text}>
           <p>Персональні дані Користувача Адміністрація сайту інтернет-магазину може використовувати для таких потреб:</p>
           <p>4.1.1. Ідентифікація Користувача, зареєстрованого на сайті Інтернет-магазину, для оформлення замовлення і (або) укладення Договору купівлі-продажу товару дистанційним способом з SICVOLO.COM.</p>
          <p>4.1.2. Надання Користувачеві доступу до персоналізованих ресурсів Сайту інтернет-магазину.</p>
          <p>4.1.3. Встановлення з Користувачем зворотного зв'язку, включаючи напрямок повідомлень, запитів, що стосуються використання Сайту інтернет-магазину, надання послуг, обробки запитів і заявок від Користувача.</p>
          <p>4.1.4. Визначення місцязнаходження Користувача для безпеки і заради запобіганню шахрайству.</p>
          <p>4.1.5. Підтвердження правдивості та повноти персональних даних, наданих Користувачем.</p>
          <p>4.1.6. Створення облікового запису для здійснення покупок, якщо Користувач дав згоду на створення облікового запису.</p>
          <p>4.1.7. Сповіщення Користувача Сайту інтернет-магазину щодо статусу Замовлення.</p>
          <p>4.1.8. Обробка і отримання платежів, підтвердження податку або податкових пільг, визначення права на отримання кредитної лінії Користувачем.</p>
          <p>4.1.9. Надання Користувачеві ефективної клієнтської і технічної підтримки у разі виникнення проблем, що пов'язані з використанням Сайту інтернет-магазину.</p>
          <p>4.1.10. Надання Користувачеві з його згоди інформації щодо оновлення продукції, спеціальних пропозицій, інформації про ціни, розсилки новин та інших відомостей від імені Інтернет-магазину або від імені партнерів Інтернет-магазину.</p>
          <p>4.1.11. Здійснення рекламної діяльності за згодою Користувача.</p>
          <p>4.1.12. Надання доступу Користувачеві до сайтів або сервісів партнерів Інтернет-магазину для отримання продуктів, оновлень і послуг.</p>
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>5.	СПОСОБИ І ТЕРМІНИ ОБРОБКИ ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ</p>
       <div className={styles.policy_item_text}>
             
           <p>5.1. Обробка персональних даних Користувача здійснюється без обмеження терміну будь-яким законним способом, зокрема в інформаційних системах персональних даних через використання засобів автоматизації або без них.</p>
           <p>5.2. Користувач погоджується з тим, що Адміністрація сайту має право передавати персональні дані третім особам, кур'єрським службам, організаціям поштового зв'язку, операторам електрозв'язку винятково з метою виконання замовлення Користувача, оформленого на Сайті інтернет-магазину, включаючи доставку Товару.</p>
           <p>5.3. Персональні дані Користувача можуть бути передані уповноваженим органам державної влади лише на підставах та в порядку, що встановлені законодавством України.</p>
           <p>5.4. У разі втрати або розголошення персональних даних Адміністрація сайту інформує Користувача щодо втрати або розголошення персональних даних.</p>
           <p>5.5. Адміністрація сайту робить всі організаційні й технічні заходи для захисту персональної інформації Користувача від неправомірного або випадкового доступу, знищення, перекручення, блокування, копіювання, поширення, а також від інших неправомірних дій третіх осіб.</p>
           <p>5.6. Адміністрація сайту спільно з Користувачем вживає всіх необхідних заходів щодо запобігання збитків або інших негативних наслідків, спричинених втратою або через розголошення персональних даних Користувача.</p>
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>6.	ЗОБОВ'ЯЗАННЯ СТОРІН</p>
       <div className={styles.policy_item_text}>
           <p>6.1. Користувач зобов'язаний:</p> 
          <p>6.1.1. Надати необхідну інформацію щодо персональних даних для користування Сайтом інтернет-магазину.</p>
          <p>6.1.2. Оновити, доповнити надану інформацію щодо персональних даних у разі її зміни.</p>
          <p>6.2. Адміністрація сайту зобов'язана:</p>
          <p>6.2.1. Використовувати отриману інформацію винятково для цілей, зазначених у п. 4 цієї Політики конфіденційності.</p>
          <p>6.2.2. Забезпечити зберігання конфіденційної інформації в таємниці, розголошувати персональні дані в випадках, не зазначених у даній Політиці Конфіденційності, без попередньої письмової згоди Користувача, а також не здійснювати продаж, обмін, опублікування або розголошення іншими можливими способами переданих персональних даних Користувача за винятком п.п. 5.2. і 5.3. цієї Політики Конфіденційності.</p>
          <p>6.2.3. Вживати заходів обережності для захисту конфіденційності персональних даних Користувача згідно з порядком, який зазвичай використовується для захисту такого роду інформації в діловому обороті.</p>  
          <p>6.2.4. Здійснити блокування персональних даних, що стосуються відповідного Користувача, з моменту звернення або запиту Користувача або його законного представника або уповноваженого органу з захисту прав суб'єктів персональних даних на період перевірки, у разі виявлення неправдивих персональних даних або неправомірних дій.</p> 
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>7.	ВІДПОВІДАЛЬНІСТЬ СТОРІН</p>
       <div className={styles.policy_item_text}>
           <p>7.1. Адміністрація сайту, яка не виконала свої зобов'язання, несе відповідальність відповідно до законодавства України, за винятком випадків, передбачених п.п. 5.2., 5.3. і 7.2. цієї Політики Конфіденційності</p>  
           <p>7.2. У разі втрати або розголошення Конфіденційної інформації Адміністрація сайту не несе відповідальності, якщо дана конфіденційна інформація:</p> 
           <p>7.2.1. Стала публічним надбанням до її втрати або розголошення.</p>
           <p>7.2.2. Була отримана від третьої сторони до моменту її отримання Адміністрацією сайту.</p>
           <p>7.2.3. Була розголошена за згодою Користувача.</p>
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>8.	ВИРІШЕННЯ СПОРІВ</p>
       <div className={styles.policy_item_text}>
           <p>8.1. До звернення в суд з позовом у спорах, що виникають з відносин між Користувачем сайту Інтернет-магазину і Адміністрацією сайту, обов'язковим є пред'явлення претензії (письмової пропозиції щодо добровільного врегулювання спору).</p>  
           <p>8.2. Отримувач претензії протягом 30 календарних днів з дня отримання претензії письмово повідомляє заявника щодо результатів розгляду претензії.</p> 
           <p>8.3. У разі недосягнення згоди суперечку буде передано на розгляд до судового органу відповідно до чинного законодавства України.</p>
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>9.	ДОДАТКОВІ УМОВИ</p>
       <div className={styles.policy_item_text}>
           <p>9.1. Адміністрація сайту має право вносити зміни в Політику конфіденційності без згоди Користувача.</p>   
           <p>9.2. Нова Політика конфіденційності набирає чинності з моменту її розміщення на Сайті інтернет-магазину, якщо інше не передбачено новою редакцією Політики конфіденційності.</p>
       </div>
     </div>
      </>)}



      {i18n.language === "ru" && (<>
        <h1 >Политика конфиденциальности</h1>
     
   
     <p className={styles.policy_wrapperLast}>Данная Политика конфиденциальности персональных данных (далее - Политика конфиденциальности) действует относительно всей информации, которую интернет-магазин SICVOLO.COM может получить о Пользователе во время использования сайта, его программ и продуктов.</p>




<div className={styles.policy_item}>
       <p className={styles.policy_item_title}>1.	ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</p>
       <div className={styles.policy_item_text}>
          <p>1.1. В данной Политике конфиденциальности используются следующие термины: </p>
          <p>1.1.1. "Администрация сайта интернет-магазина (далее - Администрация сайта)" - уполномоченные сотрудники по управлению сайтом, который организуют и (или) осуществляют обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными. </p>
          <p>1.1.2. "Персональные данные" - любая информация, относящаяся прямо или косвенно к определенной или определенной физической особе (субъекту персональных данных). </p>
          <p>1.1.3. "Обработка персональных данных" - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. </p>
          <p>1.1.4. "Конфиденциальность персональных данных" - обязательное для соблюдения Оператором или другими лицами, получившими доступ к персональным данным лица, требование не допускать их распространения без согласия субъекта персональных данных или без наличия иного законного основания. </p>
          <p>1.1.5. "Пользователь сайта интернет-магазина (далее Пользователь)" - лицо, имеющее доступ к Сайту с помощью сети Интернет и использующее Сайт интернет-магазина. </p>
          <p>1.1.6. "Cookies" - небольшой фрагмент данных, отправленный веб-сервером, хранящийся на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открытия страницы соответствующего сайта. </p>
          <p>1.1.7. "IP-адрес" - уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>
       </div>
     </div>


     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>2.	ОБЩИЕ ПОЛОЖЕНИЯ</p>
       <div className={styles.policy_item_text}>
          <p>2.1. Использование Пользователем сайта интернет-магазина означает согласие с данной Политикой конфиденциальности и условиями обработки персональных данных Пользователя. </p>
          <p>2.2. Соглашаясь с использованием файлов cookies при первом посещении нашего веб-сайта, согласно положениям данных Правил, Пользователь предоставляет разрешение на использование файлов cookies при каждом последующем визите. </p>
          <p>2.3. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта интернет-магазина. </p>
          <p>2.4. Данная Политика конфиденциальности применяется только к сайту интернет-магазина SICVOLO.COM. Интернет-магазин не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте интернет-магазина. </p>
          <p>2.5. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта интернет-магазина. 2.6. Перед тем, как разглашать на сайте персональную информацию третьей стороны, Пользователь должен получить согласие этой стороны как на разглашение, так и на обработку этой информации в соответствии с данными правилами.</p>
          
       </div>
     </div>


     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>3.	ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</p>
       <div className={styles.policy_item_text}>
          <p>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта интернет-магазина по нераскрытию и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь должен по требованию предоставить Администрации сайта при регистрации на сайте интернет-магазина или при оформлении заказа для приобретения Товара.</p>
          <p>3.2. Персональные данные, которые подлежат обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путем заполнения регистрационной формы на Сайте интернет-магазина SICVOLO.COM в разделе "Оформить заказ" и содержат следующую информацию: </p>
          <p>3.2.1. фамилия, имя, отчество Пользователя; </p>
          <p>3.2.2. контактный телефон Пользователя; </p>
          <p>3.2.3. адрес электронной почты (e-mail); </p>
          <p>3.2.4. адрес доставки Товара; </p>
          <p>3.2.5. место проживания Пользователя. </p>
          <p>3.3. Интернет-магазин защищает данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы: </p>
          <ul>
            <li>IP-адрес; </li>
            <li>IP-адрес; </li>
            <li>информация о браузере (или другой программе, которая осуществляет доступ к показу рекламы); </li>
            <li>время доступа; </li>
            <li>адрес страницы, на которой размещен рекламный блок; </li>
            <li>реферер (адрес предыдущей страницы).  </li>
          </ul>
          <p>3.3.1. Отключение cookies может привести к невозможности доступа к частям сайта интернет-магазина, требующим авторизации. </p>
          <p>3.3.2. Интернет-магазин осуществляет сбор статистики по IP-адресам своих посетителей. Эта информация используется с целью выявления и решения технических проблем, для контроля законности проведенных финансовых платежей. </p>
          <p>3.4. Любая другая персональная информация, не упомянутая выше (история покупок, используемые браузеры и операционные системы и т. д.), подлежит надежному хранению и не распространяется, за исключением случаев, предусмотренных пунктами 5.2. и 5.3. настоящей Политики конфиденциальности.</p>
        
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>4.	ЦЕЛЬ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</p>
       <div className={styles.policy_item_text}>
           <p>4.1. Администрация сайта интернет-магазина может использовать персональные данные Пользователя для следующих целей: </p>
           <p>4.1.1. Идентификация Пользователя, зарегистрированного на сайте интернет-магазина, для оформления заказа и (или) заключения Договора купли-продажи товара дистанционным способом на SICVOLO.COM. </p>
           <p>4.1.2. Предоставление Пользователю доступа к персонализированным ресурсам Сайта интернет-магазина. </p>
           <p>4.1.3. Установление с Пользователем обратной связи, включая направление сообщений, запросов, касающихся использования Сайта интернет-магазина, предоставления услуг, обработки запросов и заявок от Пользователя. </p>
           <p>4.1.4. Определение местонахождения Пользователя для обеспечения безопасности и предотвращения мошенничества. </p>
           <p>4.1.5. Подтверждение достоверности и полноты персональных данных, предоставленных Пользователем. </p>
           <p>4.1.6. Создание учетной записи для совершения покупок, если Пользователь согласился на создание учетной записи. </p>
           <p>4.1.7. Уведомление Пользователя Сайта интернет-магазина о статусе Заказа. </p>
           <p>4.1.8. Обработка и получение платежей, подтверждение налога или налоговых льгот, определение права на получение кредитной линии Пользователем. </p>
           <p>4.1.9. Предоставление Пользователю эффективной клиентской и технической поддержки в случае возникновения проблем, связанных с использованием Сайта интернет-магазина. </p>
           <p>4.1.10. Предоставление Пользователю по его согласию информации о обновлении продукции, специальных предложениях, информации о ценах, рассылке новостей и другой информации от имени Интернет-магазина или от имени партнеров Интернет-магазина. </p>
           <p>4.1.11. Осуществление рекламной деятельности с согласия Пользователя. </p>
           <p>4.1.12. Предоставление доступа Пользователю к сайтам или сервисам партнеров Интернет-магазина для получения продуктов, обновлений и услуг.</p>
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>5.	МЕТОДЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</p>
       <div className={styles.policy_item_text}>
          <p>5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока любым законным способом, включая использование информационных систем персональных данных с использованием средств автоматизации или без них. </p>
          <p>5.2. Пользователь соглашается с тем, что Администрация сайта имеет право передавать персональные данные третьим лицам, курьерским службам, организациям почтовой связи, операторам электросвязи исключительно с целью выполнения заказа Пользователя, оформленного на Сайте интернет-магазина, включая доставку Товара. </p>
          <p>5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти только на основаниях и в порядке, установленных законодательством Украины. </p>
          <p>5.4. В случае утраты или разглашения персональных данных Администрация сайта информирует Пользователя о такой утрате или разглашении персональных данных. </p>
          <p>5.5. Администрация сайта предпринимает все организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, искажения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц. </p>
          <p>5.6. Администрация сайта совместно с Пользователем принимает все необходимые меры для предотвращения ущерба или иных негативных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>
          
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>6.	ОБЯЗАННОСТИ СТОРОН</p>
       <div className={styles.policy_item_text}>
          <p>6.1. Пользователь обязан: </p>
          <p>6.1.1. Предоставить необходимую информацию относительно персональных данных для пользования Сайтом интернет-магазина. </p>
          <p>6.1.2. Обновлять и дополнять предоставленную информацию относительно персональных данных в случае их изменения. </p>
          <p>6.2. Администрация сайта обязана: </p>
          <p>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 данной Политики конфиденциальности. </p>
          <p>6.2.2. Обеспечивать сохранение конфиденциальной информации в тайне, разглашать персональные данные в случаях, не указанных в данной Политике Конфиденциальности, без предварительного письменного согласия Пользователя, а также не осуществлять продажу, обмен, публикацию или раскрытие иными возможными способами переданных персональных данных Пользователя за исключением п.п. 5.2. и 5.3. данной Политики Конфиденциальности. </p>
          <p>6.2.3. Принимать меры осторожности для защиты конфиденциальности персональных данных Пользователя в соответствии с порядком, который обычно используется для защиты такого рода информации в деловом обороте. </p>
          <p>6.2.4. Осуществлять блокирование персональных данных, касающихся соответствующего Пользователя, с момента обращения или запроса Пользователя или его законного представителя или уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае обнаружения недостоверных персональных данных или неправомерных действий.</p>
          
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>7.	ОТВЕТСТВЕННОСТЬ СТОРОН</p>
       <div className={styles.policy_item_text}>
           <p>7.1. Администрация сайта, которая не выполнит свои обязательства, несет ответственность в соответствии с законодательством Украины, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. данной Политики Конфиденциальности. </p>
           <p>7.2. В случае утраты или раскрытия Конфиденциальной информации Администрация сайта не несет ответственности, если данная конфиденциальная информация: </p>
           <p>7.2.1. Стала общественным достоянием до ее утраты или раскрытия. </p>
           <p>7.2.2. Была получена от третьей стороны до момента ее получения Администрацией сайта. </p>
           <p>7.2.3. Была раскрыта с согласия Пользователя.</p>
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>8.	РЕШЕНИЕ СПОРОВ</p>
       <div className={styles.policy_item_text}>
           <p>8.1. До обращения в суд с иском в спорах, возникающих из отношений между Пользователем сайта Интернет-магазина и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора). </p>   
           <p>8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии письменно уведомляет заявителя о результатах рассмотрения претензии. </p>
           <p>8.3. В случае недостижения согласия спор передается на рассмотрение судебного органа в соответствии с действующим законодательством Украины.</p>
       </div>
     </div>

     <div className={styles.policy_item}>
       <p className={styles.policy_item_title}>9.	ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</p>
       <div className={styles.policy_item_text}>
           <p>9.1. Администрация сайта имеет право вносить изменения в Политику конфиденциальности без согласия Пользователя.  </p>   
           <p>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте интернет-магазина, если иное не предусмотрено новой редакцией Политики конфиденциальности. </p>
       </div>
     </div>
      </>)}



    </div>

    
        </>
    
  )
}
